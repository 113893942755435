<template>
  <v-container>
    <v-col>
      <v-text-field
       dense
       outlined  
       label="SSID"
       v-model="ssid"
       
      ></v-text-field>
    </v-col>
    <v-col>
      <v-select
        dense
        outlined
        :items="['infrastructure', 'ap']"
        :label="$t('Wireless Mode')"
        v-model="value.mode" 
        @input="update('type', $event.target.value)"
        persistent-hint
        :hint="$t('infrastructure -> wifi client, ap -> access point')"
      ></v-select>
    </v-col>
  </v-container>
</template>
<script>
export default {

  name: 'Wireless',
  props: ['value'],
  data() {
    return {
      
    }
  },
  methods: {
    update(key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    },
     updateSSID(key, value) {
       console.log(value)
      this.$emit('input', { ...this.value, [key]: this.utf8_to_b64(value)})
    },
    utf8_to_b64( str ) {
      return window.btoa(unescape(encodeURIComponent( str )));
    },
    b64_to_utf8( str ) {
      return decodeURIComponent(escape(window.atob( str )));
    },

  },
  computed: {
    ssid: {
      get () { return this.b64_to_utf8(this.value.ssid)},
      set(val) {this.$emit('input', { ...this.value, ['ssid']: this.utf8_to_b64(val)})}
     
    }
  }

}
</script>