<template>
  <v-container>
    <v-col>
      <v-row>
        <v-col>
          <v-select
            :items="['auto', 'manual']"
            v-model="value.method"
            :label="$t('Mode')"
            outlined
            @input="update('type', $event.target.value)"
          ></v-select>
        </v-col>
      </v-row>
    </v-col>
    <v-card-text v-if="value.method != 'auto'">
      <v-col>
        <v-row class="ma-auto">
          <v-col>
            <h4>{{ $t("IPv4 setting") }}</h4>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t("IP address") }}
                    </th>
                    <th class="text-left">
                      {{ $t("Mask") }}
                    </th>
                    <th class="text-left">
                      {{ $t("Gateway") }}
                    </th>
                    <th class="text-left">
                      {{ $t("Remove") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in value.addresses" :key="item[0]">
                    <td>{{ intToIP(item[0]) }}</td>
                    <td>{{ item[1] }}</td>
                    <td>{{ intToIP(item[2]) }}</td>
                    <td>
                      <v-btn
                        color="red"
                        small
                        icon
                        class="ma-2 white--text"
                        @click="remove(item[0])"
                      >
                        <v-icon> mdi-minus </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row class="ma-auto">
          <v-col cols="12" md="3">
            <v-text-field
              dense
              outlined
              v-model="ipadress"
              :label="$t('Address')"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <v-text-field dense outlined v-model="mask" :label="$t('Mask')">
            </v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <v-text-field
              dense
              outlined
              v-model="gateway"
              :label="$t('Gateway')"
            >
            </v-text-field>
          </v-col>
        
          <v-col cols="12" md="3" class="mt-2" align="right">
            <v-btn small @click="add"> {{ $t("Add") }}</v-btn>
          </v-col>
        </v-row>
        
        <v-row class="ma-auto">
          <v-col>
            <h4>{{ $t("DNS setting") }}</h4>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t("DNS addresses") }}
                    </th>
                    <th class="text-left">
                      {{ $t("Remove") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, n) in value.dns" :key="n">
                    <td>{{ intToIP(item) }}</td>

                    <td>
                      <v-btn
                        color="red"
                        small
                        icon
                        class="ma-2 white--text"
                        @click="removeDNS(item)"
                      >
                        <v-icon> mdi-minus </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              dense
              outlined
              v-model="dns"
              :label="$t('Dns')"
              @input="update('type', $event.target.value)"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" align="right">
            <v-btn class="mt-2" small @click="addDNS">{{ $t("Add") }}</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-card-text>
  </v-container>
</template>

<script>
export default {
  name: "Network",
  props: ["value"],
  data() {
    return {
      ipadress: "",
      mask: "",
      gateway: "",
      dns: "",
    };
  },
  methods: {
    update(key, value) {
      this.$emit("input", { ...this.value, [key]: value });
    },
    intToIP(int) {
      const part1 = int & 255;
      const part2 = (int >> 8) & 255;
      const part3 = (int >> 16) & 255;
      const part4 = (int >> 24) & 255;

      return part1 + "." + part2 + "." + part3 + "." + part4;
    },
    dot2num(dot) {
      var d = dot.split(".");
      return ((+d[3] * 256 + +d[2]) * 256 + +d[1]) * 256 + +d[0];
    },
    remove(key) {
      console.log(key);
      const na = this.value.addresses.filter(
        (v) => v[0] !== key || isNaN(v[0])
      );
      this.$emit("input", { ...this.value, ["addresses"]: na });
    },
    add() {
      const na = this.value.addresses;
      na.push([
        this.dot2num(this.ipadress),
        parseInt(this.mask),
        this.dot2num(this.gateway)|| 0,
      ]);
      this.$emit("input", { ...this.value, ["addresses"]: na });
    },
    addDNS() {
      const na = this.value.dns;
      na.push(this.dot2num(this.dns).toString());
      this.$emit("input", { ...this.value, ["dns"]: na });
    },
    removeDNS(key) {
      console.log(key);
      const na = this.value.dns.filter((v) => v !== key || isNaN(v));
      this.$emit("input", { ...this.value, ["dns"]: na });
    },
  },
};
</script>

