
<template>
  <v-card 
    flat 
  >
    <v-toolbar
      color="black"
      dark
      text
      dense
    >
    <v-toolbar-title>{{$t("Network settings")}}</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn
     color="#196619"
     dark
     text
     @click="reload"
    >{{ $t("Reload connections")}}</v-btn>
    </v-toolbar>
    <v-card-text>
    <v-row  v-if="settings instanceof Array" class="mt-2">

      <v-col  v-for="setting, n in settings" :key="setting.connection.id" cols="12" md="6">
        <v-card 
        outlined
        v-if="!['vpn', 'tun'].includes(settings[n].connection.type)"
        >
          <v-card-title>{{settings[n].connection.id}}</v-card-title>
          <v-card-subtitle>{{settings[n].connection.type}} -- {{settings[n].connection['interface-name']}} </v-card-subtitle>
          <wireless v-if="settings[n]['802-11-wireless']" v-model="settings[n]['802-11-wireless']"></wireless>
          <wireless-security v-if="settings[n]['802-11-wireless-security']" v-model="settings[n]['802-11-wireless-security']"></wireless-security>
          <network v-if="!['gsm', 'vpn', 'tun'].includes(settings[n].connection.type)" v-model="settings[n].ipv4"/>
          <gsm-config v-if="settings[n].connection.type === 'gsm'" v-model="settings[n].gsm"/>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="#196619" @click="save(n)">{{$t('Save')}}</v-btn>
          
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
     <v-row  v-else class="mt-2">
      <v-col cols="12" md="6" align="center">
      <v-card 
        outlined
      > 
      <v-card-title>{{settings.name}}</v-card-title>
        <NetworkSystemd  v-model="settings"/>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="#196619" @click="saveSystemd()">{{$t('Save')}}</v-btn>
        </v-card-actions>
      </v-card>
      </v-col>
    </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Network from '../components/Network.vue'
import Wireless from '../components/Wireless.vue'
import WirelessSecurity from '../components/WirelessSecurity.vue'
import GsmConfig from '../components/GsmConfig.vue'
import NetworkSystemd from '../components/NetworkSystemd.vue'
export default {
    name: "Ipsetting",
    components: {
      Network,
      NetworkSystemd,
      Wireless,
      WirelessSecurity,
      GsmConfig, 
    },
    data() { 
      return {
        settings: null,
       
      } 
    },
    methods: {
       async initialize() {
        let res = null;
        try {
          res = await this.$http.get("/nm");
          this.settings = res.data;
        } catch (e) {
          console.log(e);
          this.$emit("alertMsg", {
            type: "error",
            text: e.message + "\n" + res?.error,
          });
        }
      },

      async save (id) {
        let res = null;
        try {
          res = await this.$http.put("/nm", this.settings[id] );
          this.$emit("alertMsg", {
            type: "success",
            text: "Data saved OK",
          });
        } catch (e) {
          console.log(e);
          this.$emit("alertMsg", {
            type: "error",
            text: e.message + "\n" + res?.error,
          });
        }

      },
      async saveSystemd() {
        let res = null;
        try {
          res = await this.$http.put("/nm/systemd", this.settings );
          this.$emit("alertMsg", {
            type: "success",
            text: "Data saved OK",
          });
        } catch (e) {
          console.log(e);
          this.$emit("alertMsg", {
            type: "error",
            text: e.message + "\n" + res?.error,
          });
        }
      },
      async reload () {
        try {
          await this.$http.get("/nm/reload");
        } catch (e) {
          console.log(e);
          this.$emit("alertMsg", {
            type: "error",
            text: e.message + "\n",
          });
        }
      }
    },
    created () {
      this.initialize()
    }


}
</script>

<style>

</style>