<template>
  <v-container>
    <v-col>
      <h5>{{$t('Wireless security')}}</h5>
      <v-text-field 
       label="PSK-password"
       dense 
       outlined
       v-model="value.psk"
       :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
       @click:append="show = !show"
       counter
       :hint="$t('At least 8 characters')"
       :rules="rules"
       :type="show ? 'text' : 'password'"
       @input="update('type', $event.target.value)"
      ></v-text-field>
    </v-col>
  
  </v-container>
</template>
<script>
export default {

  name: 'WirelessSecurity',
  props: ['value'],
  data() {
    return {
      show: false,
      psk: '',
      rules: [
       value => !!value || this.$t('Required'),
       v => v.length >= 8 || this.$t('Min 8 characters'),
      ],

    }
    
  },
  methods: {
    update(key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    },
    

  },

}
</script>